import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Poliza } from "./components/poliza";
import { Footer } from "./components/footer";
import { Contact } from "./components/contact";
import { PolicyTypes } from "./components/policyTypes";
import { Alianza } from "./components/alianza";
import { Semblanza } from "./components/semblanza";
import { Testimonials } from "./components/testimonials";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
//      <Testimonials data={landingPageData.Testimonials} />

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Semblanza data={landingPageData.Semblanza} />
      <Services data={landingPageData.Services} />
      <PolicyTypes data={landingPageData.PolicyTypes} />
      <Poliza data={landingPageData.Polizas} />
      <Alianza data={landingPageData.Alianza} />
      <Contact data={landingPageData.Contact} />
      <Footer />
    </div>
  );
};

export default App;
