import React from 'react';

export const PolicyTypes = ({ data }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div id="policy-types" className="text-left">
      <div className="container">
        <div className="section-title">
          <h2 className="text-center">Tipos de Poliza</h2>
          <p className="accordion-content">{data.description}</p>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                {data.packages.map((pkg, index) => (
                  <th key={index}>{pkg.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.features.map((feature, index) => (
                <tr key={feature}>
                  <td>{feature}</td>
                  {data.packages.map((pkg) => {
                    const featureIndex = data.features.length - 1; // index of the last feature
                    return (
                      <td key={pkg.name + feature}>
                        {index === featureIndex ? (
                          "Contratación Opcional"
                        ) : pkg.isAvailable[index] ? (
                          <span className="text-success">✓</span>
                        ) : (
                          <span className="text-danger">✕</span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PolicyTypes;
