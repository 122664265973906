import React from "react";

export const Semblanza = (props) => {
  return (
    <div id="semblanza">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/guillermo.jpeg" className="img-responsive" alt="Guillermo Bonilla" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Guillermo Bonilla</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Trayectoria Académica</h3>
              <ul>
                {props.data ? props.data.trayectoriaAcademica.map((item, index) => (
                  <li key={`academica-${index}`}>{item}</li>
                )) : "loading"}
              </ul>
            </div>
          </div>
        </div>
        <h3>Trayectoria Laboral</h3>
        <div className="timeline">
          {props.data ? props.data.trayectoriaLaboral.map((item, index) => (
            <div key={`laboral-${index}`} className={`timeline-item ${index % 2 === 0 ? 'top' : 'bottom'}`}>
              <div className="timeline-content">{item}</div>
            </div>
          )) : "loading"}
        </div>
      </div>
    </div>
  );
};
