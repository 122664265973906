import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



export const Alianza = ({ data }) => {
  const settings = {
    dots: false,           
    infinite: true,
    speed: 500,
    autoplay: true,        
    autoplaySpeed: 2000, 
    pauseOnHover: false,  
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: null,       
    prevArrow: null,      
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,   // Remove arrows on small screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,   // Remove arrows on medium screens
        },
      },
    ],
  };
  return (
    <div id="alianza" className="text-center">
      <div className="container">
          <div className="section-title">
            <h2>Nuestras Alianzas</h2>
          </div>
          <div>
            <Slider {...settings}>
              {data && data.map((item, index) => (
                <div key={index} className="carousel-slide">
                  <a href={`${item.link}`}><img src={`../img/alianza/${item.img}`} alt={`Slide ${index}`} /></a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

  );
};

export default Alianza;
