import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/NBA_About.png" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Sobre nosotros</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section">
            <div className="content-area">
            <h3>MISIÓN</h3>
              <p>{props.data ? props.data.mission : "Cargando misión..."}</p>
            </div>
          </div>
          <div className="section">
            <div className="content-area">
            <h3>VISIÓN</h3>
              <p>{props.data ? props.data.vision : "Cargando visión..."}</p>
            </div>
          </div>
          <div className="section">
            <div className="content-area">
            <h3>VALORES</h3>
              <ul>
                {props.data
                  ? props.data.valores.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))
                  : "loading"}
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};
