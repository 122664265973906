import React from 'react';
//<p><i className="fa fa-map-marker"></i> Calle Emiliano Zapata 23A, 76800 San Juan del Río Qro., México</p>
export const Footer = () => {
  return (
    <footer className="footer bg-light">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-lg-4 col-sm-4 hidden-xs">
          <ul className="footer-menu">
              <li><a href="#features">Sobre Nosotros</a></li>
              <li><a href="#services">Servicios</a></li>
              <li><a href="#Poliza">Polizas</a></li>
              <li><a href="#alianza">Nuestras Alianzas</a></li>
              <li><a href="#contacto">Contacto</a></li>
              </ul>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-4">
            <h5>Contacto</h5>
            <p><i className="fa fa-envelope"></i> contacto@blegal.com.mx</p>
            <p><i className="fa fa-phone"></i> +52 427 150 1138</p>
            
            </div>
          <div className="col-md-4 col-lg-4 col-sm-4 hidden-xs">
            <a href="#!" className="footer-logo">
              <img src="img/logo.png" alt="Company Logo" height="150" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
