import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros servicios</h2>
          <p>
            Explora nuestros servicios a continuación y descubre cómo podemos ayudarte a alcanzar tus objetivos con confianza y tranquilidad.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-xs-6 col-sm-6 col-md-3">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <i className={d.icon}></i>
                        <h3>{d.name}</h3>
                      </div>
                      <div className="flip-card-back">
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                        {/* Include any additional info here */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
