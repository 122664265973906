import React, { useState } from "react";

const AccordionItem = ({ title, introduction, requirements, downloadables }) => {
  const [isOpen, setOpen] = useState(false);

  const downloadablesSection = downloadables.map((downloadable, index) => (
    <li key={index}>
      <a href={downloadable.url} download>{downloadable.name}</a>
    </li>
  ));

  return (
    <div className={`accordion-item ${isOpen ? "open" : ""}`}>
      <div className="accordion-title" onClick={() => setOpen(!isOpen)}>
        <h5>{title}</h5>
        <i className={`fa ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}></i>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <p>{introduction}</p>
          <h6 className="requerimientos-heading">Requerimientos</h6>
          <ul>{requirements.map((requirement, index) => (<li key={index}>{requirement}</li>))}</ul>
          {downloadables.length > 0 && (
            <>
              <p className="downloadables-intro">Haz clic en los enlaces a continuación para descargar los documentos relevantes:</p>
              <ul>{downloadablesSection}</ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const Poliza = ({ data }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  const steps = Object.keys(data).filter(key => key.startsWith("Step"));

  return (
    <div id="poliza" className="text-left">
      <div className="container">
        <div className="section-title">
          <h2 className="text-center">Gestiona tu poliza con nosotros</h2>
          <p className="accordion-content">{data.paragraph}</p>
        </div>
        {steps.map((step) => {
          const stepData = data[step];
          if (stepData && stepData.name) {
            return (
              <AccordionItem
                key={stepData.name}
                title={stepData.name}
                introduction={stepData.introduction}
                requirements={stepData.requirements}
                downloadables={stepData.downloadables.map(downloadable => ({
                  name: downloadable,
                  url: `../pdf/${downloadable}` // Update this path as necessary
                }))}
              />
            );
          }
          return null;
        })}
        <p className="poliza-p">¡Envia tu solicitud!</p>
        <div class="container-form">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfpaYdLI63hliSICm3eHkzvuDTzPo0BhiZI02hMDIopSntBYQ/viewform?usp=sf_link" class="google-form-link">Arrendatario/Obligado solidario</a>
        </div>
        <div class="container-form">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfRmOGVM8l5gNsq8VZiEnG23-AfLmQ9Imjv9cfD4bPNaLilUw/viewform?usp=sf_link" class="google-form-link">Arrendador/Inmobiliaria</a>
        </div>

      </div>
    </div>
  );
};

export default Poliza;
